<template>
	<div class="container-wrap">
		
		<div class="container-top">
			
			<div class="container-logo" :style="{
				marginLeft:is_mobile?'10px':'30px'
			}">
			    <el-image :src="image.logo" fit="fill"></el-image>
			</div>
			
			<div class="container-tags">
				<div :class="index == 0 ? 'container-tags-item1' : 'container-tags-item'" 
				v-for="(item,index) in tags" :key="index" @click="onSelectTag(index)">
					{{item.title}}
				</div>
			</div>
			
			<div class="container-set" @click="onSet">
			    <el-image :src="image.set" style="width: 30px;height: 30px;"></el-image>
			</div>
			
			<div class="container-set1">
				<div class="container-go" @click="onControl">
				    控制台
				</div>
				<div v-if="is_login" class="container-login1" @click="onLogin">
					退出
				</div>
				<div v-else class="container-login" @click="onLogin">
					登陸
				</div>
			</div>
			
			<!-- <div v-if="is_mobile">
				
			</div>
			<div v-else class="container-set1">
				<div class="container-go" @click="onControl">
				    控制台
				</div>
				<div v-if="is_login" class="container-login1" @click="onLogin">
					退出
				</div>
				<div v-else class="container-login" @click="onLogin">
					登入
				</div>
			</div> -->
			
		</div>
		
		<div class="container-content">
			
			<div class="profile-content1">
				<el-image :src="image.di" style="width: 100%;height: 400px;" fit="cover"></el-image>
				<!-- <div v-if="is_mobile" class="profile-content1-desc1">
					<el-image :src="image.banner"></el-image>
				</div>
				<div v-else class="profile-content1-desc">
					<el-image :src="image.banner"></el-image>
				</div> -->
				
				<div class="profile-content1-desc">
					<el-image :src="image.banner"></el-image>
				</div>
			</div>
			
			<div class="t1">
				
				<div class="t1-0" :style="{
					width:is_mobile?'80%':'50%'
				}">
					<div class="t1-title">
						tk营销软件，一個人的時間，用它幫你創造一百人的價值
					</div>
					
					<div class="t1-func">
						<div style="font-weight: bold;color: #3a3a3c;">簡單清晰的功能模塊</div>
						<div class="t1-func-desc">一鍵式的操作，您無需處理其他的任何東西，軟件會自動幫你處理任務邏輯</div>
					</div>
					<div class="t1-func">
						<div style="font-weight: bold;color: #3a3a3c;">功能種類多</div>
						<div class="t1-func-desc">转推，群发，私信，查验，起号，清除，检测，各种功能齐全,覆蓋您所需的各種功能
													</div>
					</div>
					<div class="t1-func">
						<div style="font-weight: bold;color: #3a3a3c;">安全，透明</div>
						<div class="t1-func-desc">
								所有軟件操作均在本地電腦上，讓您的帳戶做到絕對的安全，不會獲取您的帳戶任何信息</div>
					</div>
					<div class="t1-func">
						<div style="font-weight: bold;color: #3a3a3c;">多號操作</div>
						<div class="t1-func-desc">支持多號操作，擴大您的帳戶池，擺脫手動</div>
					</div>
				</div>
				
				<div class="t1-1" :style="{
					width:is_mobile?'100%':'50%'
				}">
					<el-image :src="image.t1" fit="fill"></el-image>
				</div>
			</div>
			
			<div class="t2">
				
				<div class="t1-1" :style="{
					width:is_mobile?'100%':'50%'
				}">
					<el-image :src="image.t2"></el-image>
				</div>
				
				<div class="t1-0" :style="{
					width:is_mobile?'80%':'50%'
				}">
					<div class="t1-title">
						工具
					</div>
					<div class="t1-func">
						<div style="font-weight: bold;color: #3a3a3c;">互推工具</div>
						<div class="t1-func-desc">專注于推特營銷自動化工具，群发，转推，快速擴大您的推文影響力</div>
					</div>
					<div class="t1-func">
						<div style="font-weight: bold;color: #3a3a3c;">私信工具</div>
						<div class="t1-func-desc">幫助您找到精準的消費群體用戶，幫你建立和精準用戶之間的聯繫</div>
					</div>
					<div class="t1-func">
						<div style="font-weight: bold;color: #3a3a3c;">矩阵工具</div>
						<div class="t1-func-desc">幫你快速打造矩陣，快速的幫您提高帳戶的粉絲用戶</div>
					</div>
					<div class="t1-func">
						<div style="font-weight: bold;color: #3a3a3c;">自动发推工具</div>
						<div class="t1-func-desc">簡單的編輯您的發送推文，幫你自動發送推文，減少人共成本</div>
					</div>
					<div class="t1-func">
						<div style="font-weight: bold;color: #3a3a3c;">更多的工具到您的控制後台查看</div>
					</div>
				</div>
			</div>
		</div>
		
		<el-drawer
		    v-model="showSetting"
			title="設置"
		    direction="rtl"
			size="30%"
		    :before-close="handleClose">
		    <div class="index-drawer-box">
				
				
				<div v-if="!is_login" class="index-drawer-box-item" @click="onDraw(0)">
					登錄
				</div>
				<div class="index-drawer-box-item" @click="onDraw(1)">
					控制台
				</div>
				<div class="index-drawer-box-item" style="background-color: #199D76;" @click="onDraw(5)">
					公告
				</div>
				<div class="index-drawer-box-item" style="background-color: #fda75d;" @click="onDraw(2)">
					產品
				</div>
				
				<div class="index-drawer-box-item" style="background-color: #d458bf;" @click="onDraw(3)">
					價格
				</div>
				
				<div class="index-drawer-box-item" style="background-color: #f52f54;" @click="onDraw(4)">
					分銷
				</div>
				<div v-if="is_login" class="index-drawer-box-item" style="background-color: #f52f54;" @click="onDraw(0)">
					退出
				</div>
				
				
			</div>
		</el-drawer>
		
	</div>
</template>

<script>
	import {Settings} from "../api/index";
	export default {
		  head() {
		    return {
		      meta: [
		        { property: 'twitter:card', content: 'summary' },
		        { property: 'twitter:site', content: '@bxvpn' },
		        { property: 'twitter:title', content: 'tk自动化工具' },
		        { property: 'twitter:description', content: 'tk营销软件，一個人的時間，用它幫你創造一百人的價值' },
		        { property: 'twitter:image', content: 'https://tkinte.com/img/tt2.eac29436.png' },
		        { property: 'twitter:url', content: 'https://tkinte.com' }
		      ]
		    };
		  },
		
		data(){
			return{
				tags:[
					{title:"產品",value:0},
					{title:"價格",value:1},
					{title:"分銷",value:2},
					{title:"公告",value:3},
				],
				
				is_login:false,
				is_mobile:false,
				showSetting:false,
				
				image:{
					di:require("../assets/img/bg1.png"),
					set:require("../assets/img/iconfont/set.png"),
					t1:require("../assets/img/tt2.png"),
					t2:require("../assets/img/tt3.png"),
					logo:require("../assets/img/logo.png"),
					banner:require("../assets/img/banner.png"),
				},
				
			}
		},
		
		created() {
			this.is_mobile = this.isMobile();
			
			this.is_login = false;
			var token = localStorage.getItem('token');
			if(token){
				this.is_login = true;
			}
			
			let invite = "";
			if(this.$router.currentRoute.value.query && this.$router.currentRoute.value.query.invite){
				invite = this.$router.currentRoute.value.query.invite;
				if(!token){
					this.$router.replace("/login");
				}
			}
			localStorage.setItem("invite",invite);
			this.get_setting()
			
		},
		
		methods:{
			get_setting(){
				Settings().then(res=>{
					if(!res.data){
						return
					}else{
						localStorage.setItem("withdraw_price",res.data.withdraw_price);
						localStorage.setItem("invite_url",res.data.invite_url);
						var rate =  parseFloat(res.data.rate) * 100
						localStorage.setItem("rate",rate.toString());
						
					}
				})
			},
			isMobile() {
			    let userAgentInfo = navigator.userAgent;
			    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
			    let getArr = Agents.filter(i => userAgentInfo.includes(i));
			    return getArr.length ? true : false;
			},
			
			handleClose(){
				this.showSetting = false;
			},
			
			// 移动端设置
			onSet(){
				this.showSetting = true;
			},
			
			// 移动端处理
			onDraw(index){
				if(0==index){
					if(this.is_login){
						localStorage.setItem("token","");
						this.is_login = false;
						this.handleClose();
						return;
					}
					this.$router.replace("/login");
				}else if(1==index){
					this.onControl();
				}else if(2==index){
					this.onControlByIndex(2);
				}else if(3==index){
					this.onControlByIndex(2);
				}else if(4==index){
					this.onControlByIndex(4);
				}else if(5==index){
					window.open("http://doc.tkinte.com")
					return;
				}else{
					console.log("====");
				}
			},
			
			onLogin(){
				if(this.is_login){
					localStorage.setItem("token","");
					this.is_login = false;
					return;
				}
				let routeData = this.$router.resolve({ path: '/login' });
				window.open(routeData.href, '_blank');
			},
			
			onControl(){
				let routeData = this.$router.resolve({ path: '/profile',query:{
					index:0
				}});
				window.open(routeData.href, '_blank');
			},
			
			onControlByIndex(index){
				let routeData = this.$router.resolve({ path: '/profile',query:{
					index:index
				}});
				window.open(routeData.href, '_blank');
			},
			
			onSelectTag(index){
				if(0==index){
					this.onControlByIndex(2);
				}else if(1==index){
					this.onControlByIndex(2);
				}else if(2==index){
					this.onControlByIndex(4);
				}else if(3==index){
					window.open("http://doc.tkinte.com")
					return;
				}else{
					console.log("===");
				}
			},
			
			
		}
		
	}
</script>

<style>
@import url("./Index.css");
</style>